import * as React from 'react';
import {Button, Grid, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Divider} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ReportGmailerrorredIcon  from '@mui/icons-material/ReportGmailerrorred';
import CircularProgress from '@mui/material/CircularProgress';
import "./SendgridSpamModal.scss";
import {Dispatch, SetStateAction} from "react";

export interface ISendgridSpamModalProps {
    isSpamModalOpen: boolean;
    setIsSpamModalOpen: Dispatch<SetStateAction<any>>;
    isSpamModalLoading: boolean;
    removeOwnerEmailFromSpamList: () => void;
}

export const SendgridSpamModal: React.FC<ISendgridSpamModalProps> = ({isSpamModalOpen, setIsSpamModalOpen, isSpamModalLoading, removeOwnerEmailFromSpamList}) => {
    const handleClose = () => {
        setIsSpamModalOpen(false);
    };

    const onDialogClose = (reason: string) => {
        if (reason === "backdropClick") {
            return;
        }
    }

    return <React.Fragment>
        <Dialog
            open={isSpamModalOpen}
            keepMounted
            onClose={(reason: string) => onDialogClose(reason)}
            aria-describedby="alert-dialog-slide-description"
            disableEscapeKeyDown={true}
        >
            <DialogTitle className="dialog-title-close-button">
                Reset Spam Status
                <IconButton aria-label="close" onClick={handleClose} disabled={isSpamModalLoading}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item>
                            <ReportGmailerrorredIcon className="spam-icon" />
                        </Grid>
                    </Grid>
                    <Grid container className="spam-modal-text" justifyContent="center" alignItems="center">
                        <p>Are you sure you want to reset spam status on sendgrid?</p>
                        <p>Before resetting consider the impact of this action.</p>
                    </Grid>
                    {isSpamModalLoading ?
                        <Grid className="loading-container" container justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Grid> :
                        <Grid className="button-container" container justifyContent="center" alignItems="center">
                            <Grid className="delete-container" item xs={6} md={6}>
                                <Button className="no-text-transform delete-button" variant="contained" onClick={removeOwnerEmailFromSpamList}>Delete</Button>
                            </Grid>
                            <Grid className="cancel-container" item xs={6} md={6}>
                                <Button className="no-text-transform cancel-button" variant="outlined" onClick={handleClose}>Cancel</Button>
                            </Grid>
                        </Grid>
                    }
                </DialogContentText>
            </DialogContent>
        </Dialog>
    </React.Fragment>;
};


