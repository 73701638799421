import axios from "./axios";

export const getSendgridConfiguration = (email: string, account: string) => {
  return axios.get(`/api/v1/sendgrid/subscriptions/groups?email=${email}&account=${account}`);
};

export const saveSendgridConfiguration = (email: string, account: string, action: string, groupIds: Array<number>, global?: boolean) => {
  return axios.post(`/api/v1/sendgrid/subscriptions/groups?email=${email}&account=${account}`, {email, account, groupIds, action, global});
};

export const verifyEmail = (email: string, timeout: number) => {
  return axios.get(`/api/v1/email-validation/${email}?timeout=${timeout}`);
};

export const getHomeOwnerEmailSpamList = (email: string, account: string) => {
  return axios.get(`/api/v1/sendgrid/spam-reports?email=${email}&account=${account}`);
};

export const removeEmailFromSpamList = (email: string, account: string) => {
  return axios.post(`/api/v1/sendgrid/spam-reports`, {email, account});
};