import React from "react";
import {Box, Grid} from "@mui/material";
import {EmailSearch} from "../../../components/EmailSearch/EmailSearch";
import {SendgridInstructions} from "../../../components/SendgridInstructions/SendgridInstructions";
import {HeaderClassic} from "../../../components/HeaderClassic/HeaderClassic";
import {useSendgridManagerView} from "./SendgridManagerView.hooks";
import {Alert} from "../../../components/Alert/Alert";
import {SendgridGlobalOption} from "../../../components/SendgridGlobalOption/SendgridGlobalOption";
import {SendgridGroupsList} from "../../../components/SendgridGroupsList/SendgridGroupsList";
import {SendgridSpamStatus} from "../../../components/SendgridSpamStatus/SendgridSpamStatus";
import {SendgridSpamModal} from "../../../components/SendgridSpamModal/SendgridSpamModal";
import "./SendgridManagerView.scss";

export const SendgridManagerView = () => {
    const {
        // DATA
        groups, right, left, setRight, setLeft, globalSuppression, setGlobalSuppression, changed, initialGlobal,
        // FLAGS
        isSaving, setIsSaving, isLoading, isOnSpamList, isSpamModalOpen, setIsSpamModalLoading, isSpamModalLoading, setIsOnSpamList,
        //EMAIL SEARCH
        email, setEmail, isEmailValid, setIsEmailValid,
        // METHODS
        getData, save, removeOwnerEmailFromSpamList,
        // MESSAGES
        error, success, showInstructions, setShowInstructions, setError, setSuccess, setIsSpamModalOpen, successSpamMessage, setSuccessSpamMessage
    } = useSendgridManagerView();


    const onClickSearch = async () => {
        setShowInstructions(false);
        setIsOnSpamList(false);
        await getData();
    }

    return (
        <Box style={{paddingTop: "20px"}}>
            <HeaderClassic
              title={"Sendgrid Subscription Manager (homeowner)"}
              isDisabledButton={isSaving || isLoading || !isEmailValid || (changed === 0 && globalSuppression === initialGlobal)}
              setIsSaving={setIsSaving}
              onClick={save}/>
            <Box className="body-box">
                {
                    (error || success || successSpamMessage) &&
                    <Box paddingRight="40px" paddingBottom="80px">
                        {error && <Alert type="error" message={error} setMessage={setError}/>}
                        {success && <Alert type="success" message="We have received your request, it is currently being processed, validate the changes in 10 minutes." setMessage={setSuccess}/>}
                        {successSpamMessage && <Alert type="success" message="Email removed from spam list." setMessage={setSuccessSpamMessage}/>}
                    </Box>
                }
                <Box>
                    <EmailSearch onClickSearch={onClickSearch} email={email} setEmail={setEmail} isSavingOrLoading={isLoading || isSaving} isEmailValid={isEmailValid} setIsEmailValid={setIsEmailValid}/>
                    {showInstructions ?
                        <SendgridInstructions/> :
                        <React.Fragment>
                            <SendgridSpamStatus isOnSpamList={isOnSpamList} setIsSpamModalOpen={setIsSpamModalOpen} />
                            <Grid container alignItems="center">
                                <SendgridGlobalOption globalSuppression={globalSuppression} setGlobalSuppression={setGlobalSuppression} isLoadingOrIsSaving={isLoading || isSaving || groups.length === 0} changed={changed}/>
                                <SendgridGroupsList setRight={setRight} setLeft={setLeft} right={right} left={left} isLoadingOrIsSaving={isLoading || isSaving || groups.length === 0 || globalSuppression}/>
                            </Grid>
                        </React.Fragment>

                    }
                </Box>
            </Box>
            <SendgridSpamModal isSpamModalOpen={isSpamModalOpen} setIsSpamModalOpen={setIsSpamModalOpen} isSpamModalLoading={isSpamModalLoading} removeOwnerEmailFromSpamList={removeOwnerEmailFromSpamList} />
        </Box>
    );
};