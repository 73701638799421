import React, {Dispatch, SetStateAction} from "react";
import {Box, Button, Grid} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import "./SengridSpamStatus.scss";

export interface ISendgridSpamStatusProps {
    isOnSpamList: boolean;
    setIsSpamModalOpen: Dispatch<SetStateAction<any>>;
}

export const SendgridSpamStatus: React.FC<ISendgridSpamStatusProps> = ({isOnSpamList, setIsSpamModalOpen}) => {
    if (!isOnSpamList) {
        return <React.Fragment />
    }

    const handleOnClick = () => {
        setIsSpamModalOpen(true);
    }

    return (
        <Box>
            <Grid container>
                <Grid item xs={2} md={2}>
                    <p>Spam status</p>
                </Grid>
                <Grid container item xs={4} md={4}>
                    <WarningAmberIcon className="warning-icon" />
                    <p style={{fontStyle: 'italic'}}> User marked emails from Vacasa as spam.</p>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button className="no-text-transform custom-spam-button" onClick={handleOnClick} style={{marginTop: "8px"}} variant="outlined" disabled={false}>Reset Spam Status</Button>
                </Grid>
                <Box className="box-line-spam"></Box>
            </Grid>
            <Box className="box-line-spam"></Box>
        </Box>
    );
};