import {useEffect, useState} from "react";
import {
    getHomeOwnerEmailSpamList,
    getSendgridConfiguration,
    removeEmailFromSpamList,
    saveSendgridConfiguration
} from "../../../services/notifications.service";
import * as React from "react";
import {SendgridGroup} from "../../../types/sendgridManager";
import {Accounts, RESUBSCRIPTION, UNSUBSCRIPTION} from "../../../types/constants";
import * as _ from "lodash";

export function useSendgridManagerView() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [groups, setGroups] = useState<Array<SendgridGroup>>([]);
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<boolean>(false);
    const [globalSuppression, setGlobalSuppression] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [showInstructions, setShowInstructions] = useState<boolean>(true);
    const [left, setLeft] = React.useState<Array<SendgridGroup>>([]);
    const [right, setRight] = React.useState<Array<SendgridGroup>>([]);
    const [changed, setChanged] = React.useState<number>(0);
    const [initialGlobal, setInitialGlobal] = React.useState<boolean>(false);
    const [isOnSpamList, setIsOnSpamList] = React.useState<boolean>(false);
    const [isSpamModalOpen, setIsSpamModalOpen] = React.useState<boolean>(false);
    const [isSpamModalLoading, setIsSpamModalLoading] = React.useState<boolean>(false);
    const [successSpamMessage, setSuccessSpamMessage] = useState<boolean>(false);

    const getData = async () => {
        try {
            setIsLoading(true);
            const response = await getSendgridConfiguration(email, Accounts.HOMEOWNER);
            await fetchAndSetSpamEmail();
            setGroups(response.data?.groups);
            setGlobalSuppression(!!response.data?.global);
            setInitialGlobal(!!response.data?.global);
            setLeft(response.data?.groups?.filter((item: SendgridGroup) => item?.suppressed));
            setRight(response.data?.groups?.filter((item: SendgridGroup) => !item?.suppressed));
        } catch (e) {
            setGroups([]);
            setGlobalSuppression(false);
            setLeft([]);
            setRight([]);
            setError(`Error getting groups, ${e}`);
            console.error("Error getting groups.", e);
        } finally {
            setIsLoading(false);
        }
    };

    const removeOwnerEmailFromSpamList = async () => {
        try {
            setIsSpamModalLoading(true);
            await removeEmailFromSpamList(email, Accounts.HOMEOWNER);
            setIsOnSpamList(false);
            setSuccessSpamMessage(true);
        } catch (e) {
            setError(`Error removing email from spam list, ${e}`);
            console.error("Error removing email from spam list.", e);
        } finally {
            setIsSpamModalLoading(false);
            setIsSpamModalOpen(false);
        }
    }

    const fetchAndSetSpamEmail = async () => {
        const response = await getHomeOwnerEmailSpamList(email, Accounts.HOMEOWNER);
        const data = _.get(response, "data[1]", []);
        setIsOnSpamList(data.length > 0);
    }

    const save = async () => {
        try {
            setIsSaving(true);
            setError("");
            setSuccess(false);

            const suppressionList = getSuppression();
            const removeFromSuppressionList = getNoSuppression();

            if (globalSuppression !== initialGlobal) {
                await saveSendgridConfiguration(
                    email,
                    Accounts.HOMEOWNER,
                    globalSuppression ? UNSUBSCRIPTION : RESUBSCRIPTION,
                    (globalSuppression ? suppressionList : removeFromSuppressionList).map(i => i.id),
                    true
                );

                if (!globalSuppression && suppressionList.length > 0){
                    await saveSendgridConfiguration(email, Accounts.HOMEOWNER, UNSUBSCRIPTION, suppressionList.map(i => i.id));
                }
            } else {
                if (removeFromSuppressionList.length > 0) {
                    await saveSendgridConfiguration(email, Accounts.HOMEOWNER, RESUBSCRIPTION, removeFromSuppressionList.map(i => i.id));
                }

                if (suppressionList.length > 0) {
                    await saveSendgridConfiguration(email, Accounts.HOMEOWNER, UNSUBSCRIPTION, suppressionList.map(i => i.id));
                }
            }

            setGroups(prev => prev.map(item => ({...item, suppressed: getCurrentSuppressionValue(item.id) })));
            setInitialGlobal(prev => globalSuppression);
            setSuccess(true);
        } catch (e) {
            setError(`Error saving groups, ${e}`);
            console.error("Error saving groups.", e);
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        const suppressionList = getSuppression();
        const removeFromSuppressionList = getNoSuppression();
        setChanged(suppressionList.length + removeFromSuppressionList.length);
        // eslint-disable-next-line
    }, [right, left, groups]);

    useEffect(() => {
        if (globalSuppression) {
            setLeft([...right, ...left]);
            setRight([]);
        } else {
            setRight([...left, ...right]);
            setLeft([]);
        }

        // eslint-disable-next-line
    }, [globalSuppression]);

    const getSuppression = () => {
        return groups.filter((group: SendgridGroup) => left.find((item: SendgridGroup) => group.id === item.id && !group.suppressed));
    }

    const getNoSuppression = () => {
        return groups.filter((group: SendgridGroup) => right.find((item: SendgridGroup) => group.id === item.id && group.suppressed));
    }

    const getCurrentSuppressionValue = (id: number) => {
       return !!left.find(item => item.id === id);
    }

    return {
        isLoading,
        groups,
        isSaving,
        setIsSaving,
        email,
        setEmail,
        getData,
        success,
        error,
        left,
        right,
        setLeft,
        setRight,
        globalSuppression,
        setGlobalSuppression,
        isEmailValid,
        setIsEmailValid,
        showInstructions,
        setShowInstructions,
        setError,
        setSuccess,
        changed,
        initialGlobal,
        save,
        isOnSpamList,
        isSpamModalOpen,
        setIsSpamModalOpen,
        setIsSpamModalLoading,
        isSpamModalLoading,
        setIsOnSpamList,
        removeOwnerEmailFromSpamList,
        setSuccessSpamMessage,
        successSpamMessage
    };
}